import { ERTPCommonModelMetadata } from "./commonModelMetadata"
import { Subscription } from "./subscription"

export type ProfileUserProps = {
  name?: string,
  interests?: Array<string>,
  showDynamicRoadtripMaps?: boolean,
  enabledFeatures?: Array<string>
}

export type ProfileSystemProps = {
  email?: string,
  picture?: string,
  emailVerified?: boolean,
  maxAllowedRoadtrips?: number,
  maxAllowedStopsPerTrip?: number,
  subscriptions?: Array<Subscription>,
  roles?: Array<string>
}

export type Profile = {
  profileId: string,
} & ERTPCommonModelMetadata
  & ProfileUserProps 
  & ProfileSystemProps;


export const DEFAULT_MAX_ANONYMOUS_ROADTRIPS = 1;
export const DEFAULT_MAX_SIGNEDIN_ROADTRIPS = 3;
export const DEFAULT_MAX_SUBSCRIBED_ROADTRIPS = 100;
export const DEFAULT_MAX_ANONYMOUS_STOPSPERTRIP = 2;
export const DEFAULT_MAX_SIGNEDIN_STOPSPERTRIP = 5;
export const DEFAULT_MAX_SUBSCRIBED_STOPSPERTRIP = 100;
